import { StateCreator } from 'zustand';
import * as UpChunk from '@mux/upchunk';
import { BoundStore } from '..';
import { MutableRefObject } from 'react';

export type UploadStore = {
  _inputRef?: MutableRefObject<HTMLInputElement | null>;
  _uploadInstance?: UpChunk.UpChunk | null;
  progress: number;
  isUploading: boolean;
  pause: () => void;
  resume: () => void;
  abort: () => void;
  isPaused: () => void;
  setProgress: (progress: number) => void;
  setUploadInstance: (instance: UpChunk.UpChunk | null) => void;
  setUploadInputRef: (
    inputRef: MutableRefObject<HTMLInputElement | null>
  ) => void;
  setIsUploading: (isUploading: boolean) => void;
};

export type UploadSlice = {
  upload: UploadStore;
};

export const createUploadSlice: StateCreator<
  BoundStore,
  [],
  [],
  UploadSlice
> = (set, get) => ({
  upload: {
    isUploading: false,
    progress: 0,
    setProgress: (progress) =>
      set((state) => ({
        ...state,
        upload: {
          ...state.upload,
          progress,
        },
      })),
    setUploadInstance: (instance) =>
      set((state) => {
        return {
          ...state,
          upload: {
            ...state.upload,
            _uploadInstance: instance,
          },
        };
      }),
    setUploadInputRef: (inputRef) =>
      set((state) => {
        return {
          ...state,
          upload: {
            ...state.upload,
            _inputRef: inputRef,
          },
        };
      }),
    setIsUploading: (isUploading) =>
      set((state) => ({
        ...state,
        upload: {
          ...state.upload,
          isUploading,
        },
      })),
    abort: () =>
      set((state) => {
        const instance = state.upload._uploadInstance;
        const inputRef = state.upload._inputRef;
        if (!instance) return state;
        instance.abort();
        if (inputRef?.current) inputRef.current.value = '';
        return {
          ...state,
          upload: {
            ...state.upload,
            progress: 0,
            isUploading: false,
            _uploadInstance: undefined,
          },
        };
      }),
    pause: () =>
      set((state) => {
        const instance = state.upload._uploadInstance;
        if (!instance) return state;
        instance.pause();
        return {
          ...state,
          upload: {
            ...state.upload,
            isUploading: false,
          },
        };
      }),
    resume: () =>
      set((state) => {
        const instance = state.upload._uploadInstance;
        if (!instance) return state;
        instance.resume();
        return {
          ...state,
          upload: {
            ...state.upload,
            isUploading: true,
          },
        };
      }),
    isPaused: () => {
      return get().upload._uploadInstance?.paused;
    },
  },
});
