import { StateCreator } from 'zustand';
import { BoundStore } from '..';
import { User } from '@/modules/user/user.model';

export type AccountStore = {
  type?: 'business' | 'candidate';
  hash?: string;
  isLoading: boolean;
  initialized?: boolean;
  setAccount: (account: {
    initialized?: boolean;
    isLoading?: boolean;
    type?: 'business' | 'candidate';
    hash?: string;
  }) => void;
};

export type AccountSlice = {
  account: AccountStore;
};

export const createAccountSlice: StateCreator<
  BoundStore,
  [],
  [],
  AccountSlice
> = (set, get) => ({
  account: {
    isLoading: false,
    initialized: false,
    setAccount: (account) => {
      if (!!account.hash && !!account.type) {
        localStorage.setItem(
          'jr_account',
          JSON.stringify({
            type: account.type,
            hash: account.hash,
          })
        );
      } else {
        localStorage.removeItem('jr_account');
      }

      set((state) => ({
        ...state,
        account: {
          ...state.account,
          isLoading: !!account.isLoading,
          type: account.type,
          hash: account.hash,
          initialized:
            state.account.initialized === true ? true : !!account.initialized,
        },
      }));
    },
  },
});
