'use client';

import { BoundStore, craeteBoundStore } from '@/lib/client/store';
import { createContext, ReactNode, useContext, useRef } from 'react';
import { useStore } from 'zustand';

type BoundStoreApi = ReturnType<typeof craeteBoundStore>;

export const BoundStoreContext = createContext<BoundStoreApi | undefined>(
  undefined
);

export function BoundStoreProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<BoundStoreApi>();
  if (!storeRef.current) {
    storeRef.current = craeteBoundStore();
  }

  return (
    <BoundStoreContext.Provider value={storeRef.current}>
      {children}
    </BoundStoreContext.Provider>
  );
}

export function useBoundStore<T>(selector: (store: BoundStore) => T): T {
  const boundStoreContext = useContext(BoundStoreContext);

  if (!boundStoreContext) {
    throw new Error('useBoundStore must be used within BoundContextProvider');
  }

  return useStore(boundStoreContext, selector);
}
