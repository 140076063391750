import React from 'react';
import { cn } from '@/lib/utils/cn';
import './loaders.css';

type DoubleLoaderProps = {
  className?: string;
};

export function DoubleLoader({ className }: DoubleLoaderProps) {
  return <div className={cn('loader', className)}></div>;
}

type LoaderProps = {
  className?: string;
};

export function Loader({ className }: LoaderProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      className={cn('w-5 h-5 animate-spin', className)}
      viewBox='0 0 24 24'
    >
      <path d='M21 12a9 9 0 11-6.219-8.56'></path>
    </svg>
  );
}
