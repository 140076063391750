import { StateCreator } from 'zustand';
import { BoundStore } from '..';

export type PlayerStore = {
  muted: boolean;
  currentPlayerId?: string;
  setCurrentPlayerId: (playerId?: string) => void;
  setMuted: (muted: boolean) => void;
};

export type PlayerSlice = {
  player: PlayerStore;
};

export const createPlayerSlice: StateCreator<
  BoundStore,
  [],
  [],
  PlayerSlice
> = (set, get) => ({
  player: {
    muted: true,
    setCurrentPlayerId: (playerId) =>
      set((state) => {
        return {
          ...state,
          player: {
            ...state.player,
            currentPlayerId: playerId,
          },
        };
      }),
    setMuted: (muted) =>
      set((state) => {
        sessionStorage.setItem('jr_sound_on', `${!muted}`);
        return {
          ...state,
          player: {
            ...state.player,
            muted,
          },
        };
      }),
  },
});
